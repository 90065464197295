export const constantsMap = {
    see: 'SEE',
    reserve: 'RESERVE',
    home: 'HOME',
    showroom: 'SHOWROOM',
};

export enum CheckoutSteps {
    'PAYMENT_STEP' = 4,
}
